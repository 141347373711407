<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="12"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-img
						v-if="false"
            :src="
              userData.profile_image_url
                ? `${userData.profile_image_url}`
                : require('@/assets/images/placeholder/no-image.jpg')
            "
            width="120px"
            height="120px"
          />

          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.fullname }}
              </h4>
              <div class="card-text">
                {{ userData.email_address }}
              </div>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                v-if="$checkRights(this.$route.meta.section, 'update')"
                :to="{ name: 'user-manager-edit', params: { id: userData.id } }"
                variant="primary"
              >
                Edit User Details
              </b-button>

              <b-button
                v-if="userData.deactivated_on == null && $checkRights(this.$route.meta.section, 'delete')"
                variant="outline-danger"
                class="ml-md-1 mt-xs-1 mt-1 mt-md-0"
                @click="confirmDeactivateRecord"
              >
                Deactivate
              </b-button>

              <b-button
                v-if="userData.deactivated_on && $checkRights(this.$route.meta.section, 'delete')"
                variant="outline-success"
                class="ml-md-1 mt-xs-1 mt-1 mt-md-0"
                @click="confirmReactivateRecord"
              >
                Reactivate
              </b-button>
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <!-- <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="PhoneIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ userData.phone_number }}
              </h5>
              <small>Phone Number</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar
              variant="light-info"
              rounded
            >
              <feather-icon
                icon="SmartphoneIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ userData.telegram_id }}
              </h5>
              <small>Telegran ID</small>
            </div>
          </div>
        </div> -->
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="users-detail__table mt-2 mt-xl-0 w-100">
          <!-- UEN -->
          <tr class="">
            <th class="" style="width: 35%;">
              <div class="">
                <feather-icon
                  size="18"
                  icon="DatabaseIcon"
                  class="mr-75 font-weight-bolder"
                />
                <h6 class="font-weight-bolder">
                  UEN Number
                </h6>
              </div>
            </th>
            <td class="">
              <p class="">
                {{ userData.company_uen }}
              </p>
            </td>
          </tr>

          <!-- Company Name -->
          <tr class="">
            <th class="pb-50">
              <div class="d-flex ">
                <feather-icon
                  size="18"
                  icon="BriefcaseIcon"
                  class="mr-75 font-weight-bolder"
                />
                <h6 class="font-weight-bolder">
                  Company Name
                </h6>
              </div>
            </th>
            <td>
              <p class="">
                {{ userData.company_name }}</p>
            </td>
          </tr>

          <!-- Gender -->
          <!-- <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Gender</span>
            </th>
            <td class="pb-50">
              {{ userData.gender }}
            </td>
          </tr> -->

          <!-- Availability -->
          <!-- <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Availability</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.availability }}
            </td>
          </tr> -->

          <!-- Citizenship -->
          <!-- <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Citizenship</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.citizenship }}
            </td>
          </tr> -->

          <!-- Created On -->
          <!-- <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Created On</span>
            </th>
            <td class="pb-50">
              {{ formatDate(userData.created_on) }}
            </td>
          </tr> -->

          <!-- Phone Number -->
          <tr v-if="false">
            <th class="pb-50">
              <div class="d-flex ">
                <feather-icon
                  size="18"
                  icon="PhoneIcon"
                  class="mr-75 font-weight-bolder"
                />
                <h6 class="font-weight-bolder">
                  Phone Number
                </h6>
              </div>
            </th>
            <td>
              <p class="">
                {{ userData.phone_number }}
              </p>
            </td>
          </tr>

          <!-- Last Login -->
          <tr>
            <th class="">
              <div class="">
                <feather-icon
                  size="18"
                  icon="KeyIcon"
                  class="mr-75 font-weight-bolder"
                />
                <h6 class="font-weight-bolder">
                  Last Login
                </h6>
              </div>
            </th>
            <td>
              <p
                v-if="userData.last_login"
                class=""
              >
                {{ userData.last_login }}
              </p>
              <b-badge
                v-else
                pill
                variant="warning"
                class=""
              >
                Not yet logged in
              </b-badge>
            </td>
          </tr>
          <!-- Account Status -->
          <tr>
            <th class="">
              <div class="d-flex ">
                <feather-icon
                  size="18"
                  icon="MonitorIcon"
                  class="mr-75 font-weight-bolder"
                />
                <h6 class="font-weight-bolder">
                  Account Status
                </h6>
              </div>

            </th>
            <td>
              <b-badge
                pill
                class="h-6"
                :variant="userData.deactivated_on !== null ? 'danger' : 'success'"
              >
                {{ userData.deactivated_on !== null ? 'Deactivated' : 'Active' }}
              </b-badge>
            </td>
          </tr>

          <!-- Password Expiry -->
          <!-- <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Password Expiry</span>
            </th>
            <td class="pb-50">
              {{ formatDate(userData.password_expires_on) }}
            </td>
          </tr> -->

        </table>
      </b-col>

      <!-- Preferences -->
      <!-- <b-col
        cols="12"
        xl="3"
      >
        <div class="blog-categories mt-0">
          <h6 class="section-label mb-1">
            Preferences
          </h6>

          <div
            v-for="category in categories"
            :key="category.icon"
            class="d-flex justify-content-start align-items-center mb-75"
          >
            <b-link>
              <b-avatar
                rounded
                size="32"
                class="mr-75"
              >
                <feather-icon
                  :icon="category.icon"
                  size="16"
                />
              </b-avatar>
            </b-link>
            <b-link>
              <div class="blog-category-title text-body">
                {{ category.category }}
              </div>
            </b-link>
          </div>
        </div>
      </b-col> -->
    </b-row>
  </b-card>
</template>

<script>
import { format } from 'date-fns'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard, BButton, BAvatar, BRow, BCol, BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BLink,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    categories: [
      {
        category: 'Fashion',
        icon: 'WatchIcon',
      },
      {
        category: 'Food',
        icon: 'ShoppingCartIcon',
      },
      {
        category: 'Gaming',
        icon: 'CommandIcon',
      },
      {
        category: 'Quote',
        icon: 'HashIcon',
      },
      {
        category: 'Video',
        icon: 'VideoIcon',
      },
    ],
  }),
  methods: {
    formatDate(oldDate) {
      return format(new Date(oldDate), 'yyyy-MM-dd')
    },
    confirmDeleteRecord() {
      if (this.$checkRights(this.$route.meta.section, 'update')) {
        this.$bvModal
          .msgBoxConfirm(
            `Are you sure you want to delete "${this.userData.fullname}" account?`,
            {
              title: 'Confirm User Deletion',
              size: 'sm',
              okVariant: 'danger',
              okTitle: 'Delete',
              cancelTitle: 'Cancel',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            },
          )
          .then(value => {
            if (value) {
              this.deleteRecord()
            }
          })
      } else {
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'Inadequate permission',
              icon: 'CheckCircleIcon',
              text: 'You dont have enough rights to delete data in this section',
              variant: 'danger',
              position: 'center',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },
    deleteRecord() {
      const { id } = this.$store.state.auth.ActiveUser || {}

      if (id === this.params.data.id) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Operation Failed',
              icon: 'CheckCircleIcon',
              text: 'You cannot delete your own record.',
              variant: 'danger',
              position: 'center',
            },
          },
          { timeout: this.$longestTimeout },
        )
      } else {
        this.deactivateUser()
        // this.params.context.componentParent.get_company_users();
      }
    },

    // deactivate
    confirmDeactivateRecord() {
      if (this.$checkRights(this.$route.meta.section, 'delete')) {
        this.$bvModal
          .msgBoxConfirm(
            `Are you sure you want to deactivate "${this.userData.fullname}" account?`,
            {
              title: 'Confirm User Deactivation',
              size: 'sm',
              okVariant: 'danger',
              okTitle: 'Deactivate',
              cancelTitle: 'Cancel',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            },
          )
          .then(value => {
            if (value) {
              this.deactivateRecord()
            }
          })
      } else {
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'Inadequate permission',
              icon: 'CheckCircleIcon',
              text: 'You dont have enough rights to deactivate user',
              variant: 'danger',
              position: 'center',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },
    deactivateRecord() {
      const { email_address } = this.$store.state.auth.ActiveUser || {}
      const { user_group } = this.$store.state.auth.ActiveUser || {}

      if (email_address === this.userData.email_address) {
        this.$toastDanger('Operation Failed', 'You cannot deactivate your own record.')
      } else {
        this.showDeactivateSuccess()
      }
    },
    showDeactivateSuccess() {
      const { token } = this.$store.state.auth.ActiveUser
      const { id } = this.$route.params

      this.$http.defaults.headers.common.Authorization = `Token ${token}`
      this.isLoading = true

      this.$http
        .delete(`/api/users/${id}`, {})
        .then(response => {
          const { success, message } = response.data

          if (success) {
            this.$emit('deactivated')

            this.$toastSuccess('Account Operations', 'Account deactivated successfully.')
          } else {
            this.$toastDanger('Account Operations', message)
          }

          this.isLoading = false
        })
        .catch(error => {
          this.$handleErrorResponse(error, 'Account Operations', 'Could not deactivate the user account, please try again later')

          this.isLoading = false
        })
    },

    // reactivate
    confirmReactivateRecord() {
      if (this.$checkRights(this.$route.meta.section, 'update')) {
        this.$bvModal
          .msgBoxConfirm(
            `Are you sure you want to reactivate "${this.userData.fullname}" account?`,
            {
              title: 'Confirm User Reactivation',
              size: 'sm',
              okVariant: 'success',
              okTitle: 'Reactivate',
              cancelTitle: 'Cancel',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            },
          )
          .then(value => {
            if (value) {
              this.reactivateRecord()
            }
          })
      } else {
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'Inadequate Permission',
              icon: 'CheckCircleIcon',
              text: 'You dont have enough rights to reactivate user',
              variant: 'danger',
              position: 'center',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },
    reactivateRecord() {
      const { email_address } = this.$store.state.auth.ActiveUser || {}
      const { user_group } = this.$store.state.auth.ActiveUser || {}

      if (email_address === this.userData.email_address) {
        this.$toastDanger('Operation Failed', 'You cannot reactivate your own record.')
      } else {
        this.showReactivateSuccess()
      }
    },
    showReactivateSuccess() {
      const { token } = this.$store.state.auth.ActiveUser
      const { id } = this.$route.params

      this.isLoading = true
      this.$http.defaults.headers.common.Authorization = `Token ${token}`
      this.$http
        .put(`/api/users/${id}/reactivate`, {})
        .then(response => {
          const { success, message } = response.data

          if (success) {
            this.$emit('reactivated')

            this.$toastSuccess('Account Operations', 'Account reactivated successfully.')
          } else {
            this.$toastDanger('Account Operations', message)
          }

          this.isLoading = false
        })
        .catch(error => {
          this.$handleErrorResponse(error, 'Account Operations', 'Could not reactivate the user account, please try again later')

          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.users-detail__table {
  tr > td {
    padding: 0.4rem 0;
  }
  tr > th > div {
    display: flex;
    align-items: center;
    margin: auto 0;

    h6 {
      margin: auto 0;
    }
  }
  tr > td > * {
    margin: auto 0;
  }
}
</style>

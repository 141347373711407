<template>
  <b-card
    no-body
  >
    <b-card-body>
      <b-card-title>Permissions</b-card-title>

      <b-card-sub-title>Permission according to {{ roleName }}</b-card-sub-title>
    </b-card-body>

    <div class="mx-2">
      <b-table
        striped
        responsive
        :fields="permissionsTableFields"
        :items="permissionsData"
      >
        <template #cell(module)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <div class="custom-checkbox-border">
            <b-form-checkbox
              class="custom-checkbox-style"
              :disabled="disabled"
              :checked="data.value"
              @change="handleChange(data, $event)"
            />
          </div>
        </template>
      </b-table>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
  },
  props: {
    roleName: {
      type: String,
      default: 'roles',
    },
    userPermissions: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    permSections: {
      type: Array,
      default: () => [
        {
          name: 'Analytics',
          key: 'Analytics',
        },
        {
          name: 'Data Manager',
          key: 'Data Manager',
        },
        // {
        //   name: 'Recruitment Chatbot',
        //   key: 'Recruitment Chatbot',
        // },
        // {
        //   name: 'Events Manager',
        //   key: 'Events Manager',
        // },
        {
          name: 'Audit Log',
          key: 'Audit Log',
        },
        {
          name: 'User Manager',
          key: 'User Manager',
        },
        {
          name: 'ATS System',
          key: 'ATS System',
        },
        // {
        //   name: 'Corporate Directory',
        //   key: 'Corporate Directory',
        // },
        // {
        //   name: 'Finance',
        //   key: 'Finance',
        // },
        // {
        //   name: 'Marketplace',
        //   key: 'marketplace',
        // },
        // {
        //   name: 'Help Desk',
        //   key: 'help_desk',
        // },
      ],
    },
  },
  data() {
    return {
      serverPermissionsData: [],
      permissionsData: [],
      permissionsTableFields: [
        {
          key: 'module',
          label: 'Module',
          sortable: true,
        },
        {
          key: 'read',
        },
        {
          key: 'write',
        },
        {
          key: 'update',
        },
        {
          key: 'delete',
        },
      ],
    }
  },
  watch: {
    permissions(val, oldVal) {
      Object.entries(this.initPermissions).forEach(([key, permission]) => {
        // this.permissionsData[key] = {
        //   read: false,
        //   write: false,
        //   update: false,
        //   delete: false,
        // }
      })
    },
  },
  created() {
    const userInfo = this.$store.state.auth.ActiveUser
    let sections = []
    if (userInfo.user_group.includes('employer')) {
      const permSections = [
        {
          name: 'Data Manager',
          key: 'Data Manager',
        },
        // {
        //   name: 'Recruitment Chatbot',
        //   key: 'Recruitment Chatbot',
        // },
        // {
        //   name: 'Events Manager',
        //   key: 'Events Manager',
        // },
        {
          name: 'Audit Log',
          key: 'Audit Log',
        },
        {
          name: 'User Manager',
          key: 'User Manager',
        },
        {
          name: 'ATS System',
          key: 'ATS System',
        },
        // {
        //   name: 'Corporate Directory',
        //   key: 'Corporate Directory',
        // },
        // {
        //   name: 'Finance',
        //   key: 'Finance',
        // },
        // {
        //   name: 'Marketplace',
        //   key: 'marketplace',
        // },
        // {
        //   name: 'Help Desk',
        //   key: 'help_desk',
        // },
      ]
      sections = permSections.map(section => section.key)
    } else {
      sections = this.permSections.map(section => section.key)
    }

    if (!this.$isEmpty(this.userPermissions) && this.userPermissions.length > 0 && this.userPermissions[0].unique_name != null) {
      this.userPermissions.forEach(permission => {
        if (userInfo.user_group.includes('employer') && permission.name === 'Analytics') {
          return
        }

        if (sections.includes(permission.name || permission.unique_name)) {
          if ("unique_name" in permission === false) {
            permission.unique_name = permission.name
          }
          this.permissionsData.push({
            module: permission.name || permission.unique_name,
            key: permission.name || permission.unique_name,
            read: permission.read,
            write: permission.write,
            update: permission.update,
            delete: permission.delete,
          })
          this.serverPermissionsData.push(permission)
        } else {
          // this.permissionsData.push({
          //   module: section.name,
          //   key: section.key,
          //   read: false,
          //   write: false,
          //   update: false,
          //   delete: false,
          // })
        }
      })
    } else {
      this.permSections.sort((a,b) => (a.key.toLowerCase() > b.key.toLowerCase()) ? 1 : ((b.key.toLowerCase() > a.key.toLowerCase()) ? -1 : 0))
      this.permSections.forEach(section => {
        if (userInfo.user_group.includes('employer') && section.key === 'Analytics') {
          return
        }
        this.permissionsData.push({
          module: section.name,
          key: section.key,
          read: false,
          write: false,
          update: false,
          delete: false,
        })

        this.serverPermissionsData.push({
          title: section.name,
          section: section.name,
          unique_name: section.key,
          read: false,
          write: false,
          update: false,
          delete: false,
        })
      })
    }

  },
  mounted() {
    // this.permissionsData.sort((a,b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0))
    // this.serverPermissionsData.sort((a,b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0))
  },
  methods: {
    handleChange(data, value) {
      this.permissionsData[data.index][data.field.key] = value
      this.serverPermissionsData[data.index][data.field.key] = value
      console.table(this.serverPermissionsData)
      this.$emit('change', this.serverPermissionsData)
    },
  },
}
</script>

<style lang="scss" scoped>
// .custom-checkbox-style input{
//   background-color: #28a745!important;
//   border: 1px solid black !important;
//   border-color: #28a745!important;
// }
.custom-checkbox-border {
  height: 1.25rem;
  width: 1.25rem;
  // border: 1px solid black !important;
  box-shadow: 2px 3px #888888;
}
</style>

<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <user-manager-view-info-card
            :user-data="userData"
            @deactivated="get_user_data"
            @reactivated="get_user_data"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col
          v-if="userData.permissions"
          cols="12"
          lg="12"
        >
          <permissions-card
            :disabled="true"
            :user-permissions="userData.permissions"
          />
          <!-- <user-manager-view-permissions-card :user-data="userData" /> -->
        </b-col>
      </b-row>
    </template>

  </div>
</template>

<script>
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'

import PermissionsCard from '@/views/user-management-system/components/PermissionsCard.vue'

import UserManagerViewInfoCard from './UserManagerViewInfoCard.vue'
import UserManagerViewPermissionsCard from './UserManagerViewPermissionsCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    PermissionsCard,

    // Local Components
    UserManagerViewInfoCard,
    UserManagerViewPermissionsCard,
  },
  data: () => ({
    userData: {},
  }),
  created() {
    // this.populateMockData()
  },
  mounted() {
    this.get_user_data()
  },
  methods: {
    populateMockData() {
      const userInfo = this.$store.state.auth.ActiveUser
      const { id } = this.$route.params
      if (userInfo.user_group.includes('admin')) {
        if (id === '40af1671-c31f-4357-8481-9e62f9bd1adc') {
          this.userData = {
            id: '40af1671-c31f-4357-8481-9e62f9bd1adc',
            user_id: '40af1671-c31f-4357-8481-9e62f9bd1adc',
            fullname: 'John Doe',
            email_address: 'john.doe@headhuntershq.com',
            created_on: '29th May 2023 15:15:09',
            role_name: 'Administrator',
            team: 'Outreach',
            account_approved: true,
            account_pproval_status: true,

            company_uen: '201721612D',
            company_name: 'HEADHUNTERS HQ PTE. LTD.',
            deactivated_on: null,
            // permissions: {},
            permissions: [
              {
                  "title": "Analytics",
                  "section": "Analytics",
                  "unique_name": "Analytics",
                  "read": true,
                  "write": true,
                  "update": true,
                  "delete": true
              },
              {
                  "title": "ATS System",
                  "section": "ATS System",
                  "unique_name": "ATS System",
                  "read": true,
                  "write": true,
                  "update": true,
                  "delete": true
              },
              {
                  "title": "Audit Log",
                  "section": "Audit Log",
                  "unique_name": "Audit Log",
                  "read": true,
                  "write": true,
                  "update": true,
                  "delete": true
              },
              {
                  "title": "Data Manager",
                  "section": "Data Manager",
                  "unique_name": "Data Manager",
                  "read": true,
                  "write": true,
                  "update": true,
                  "delete": true
              },
              {
                  "title": "User Manager",
                  "section": "User Manager",
                  "unique_name": "User Manager",
                  "read": true,
                  "write": true,
                  "update": true,
                  "delete": true
              }
            ],
          }
        } else {
          this.userData = {
            id: '700164e4-3cfd-4bee-b593-01807dfe53b7',
            user_id: '700164e4-3cfd-4bee-b593-01807dfe53b7',
            fullname: 'James Bond',
            email_address: 'james.bond@headhuntershq.com',
            created_on: '31st May 2023 12:05:49',
            role_name: 'Administrator',
            team: 'Outreach',
            account_approved: true,
            account_pproval_status: true,

            company_uen: '201721612D',
            company_name: 'HEADHUNTERS HQ PTE. LTD.',
            deactivated_on: null,
            // permissions: {},
            permissions: [
              {
                  "title": "Analytics",
                  "section": "Analytics",
                  "unique_name": "Analytics",
                  "read": true,
                  "write": true,
                  "update": true,
                  "delete": true
              },
              {
                  "title": "ATS System",
                  "section": "ATS System",
                  "unique_name": "ATS System",
                  "read": true,
                  "write": true,
                  "update": true,
                  "delete": true
              },
              {
                  "title": "Audit Log",
                  "section": "Audit Log",
                  "unique_name": "Audit Log",
                  "read": true,
                  "write": true,
                  "update": true,
                  "delete": true
              },
              {
                  "title": "Data Manager",
                  "section": "Data Manager",
                  "unique_name": "Data Manager",
                  "read": true,
                  "write": true,
                  "update": true,
                  "delete": true
              },
              {
                  "title": "User Manager",
                  "section": "User Manager",
                  "unique_name": "User Manager",
                  "read": true,
                  "write": true,
                  "update": true,
                  "delete": true
              }
            ],
          }
        }
      } else if (userInfo.user_group.includes('employer')) {
        this.userData = {
          id: '700164e4-3cfd-4bee-b593-01807dfe53b7',
          user_id: '700164e4-3cfd-4bee-b593-01807dfe53b7',
          fullname: 'James Bond',
          email_address: 'james.bond@employer.com',
          created_on: '31st May 2023 12:05:49',
          role_name: 'Administrator',
          team: 'Outreach',
          account_approved: true,
          account_pproval_status: true,

          company_uen: '201721612D',
          company_name: 'HEADHUNTERS HQ PTE. LTD.',
          deactivated_on: null,
          // permissions: {},
          permissions: [
            {
                "title": "Analytics",
                "section": "Analytics",
                "unique_name": "Analytics",
                "read": true,
                "write": true,
                "update": true,
                "delete": true
            },
            {
                "title": "ATS System",
                "section": "ATS System",
                "unique_name": "ATS System",
                "read": true,
                "write": true,
                "update": true,
                "delete": true
            },
            {
                "title": "Audit Log",
                "section": "Audit Log",
                "unique_name": "Audit Log",
                "read": true,
                "write": true,
                "update": true,
                "delete": true
            },
            {
                "title": "Data Manager",
                "section": "Data Manager",
                "unique_name": "Data Manager",
                "read": true,
                "write": true,
                "update": true,
                "delete": true
            },
            {
                "title": "User Manager",
                "section": "User Manager",
                "unique_name": "User Manager",
                "read": true,
                "write": true,
                "update": true,
                "delete": true
            }
          ],
        }
      }
    },
    get_user_data() {
      const { id } = this.$route.params

      this.$http
        .get(`/api/users/${id}`)
        .then(response => {
          this.userData = response.data.user
        })
        .catch(error => {
          this.$handleErrorResponse(error, 'Operation Failed', "Error retrieving the user's record")

          this.isLoading = false
        })
    },
  },
}
</script>

<style>

</style>
